import React, { useEffect } from "react";
import GoTrue, { Token, User } from 'gotrue-js';

export const auth = new GoTrue({
    APIUrl: 'https://arthurmachen.co.uk/.netlify/identity',
    setCookie: true,
});

interface AuthProviderProps {
    children: React.ReactElement;
}


interface IContext {
    user?: User | null;
    isLoggedIn?: Token | null;
    showLogin?: boolean;
    onUpdateShowLogin: (state?: boolean) => void;
    onLogin: (token?: Token) => void;
    onLogout: () => void;
    authReady: boolean | null;
}

export const INITIAL_STATE: IContext = {
    user: null,
    isLoggedIn: null,
    showLogin: false,
    onUpdateShowLogin: () => {},
    onLogin: () => {},
    onLogout: () => {},
    authReady: false,
};

export const Context = React.createContext<IContext>(INITIAL_STATE);

export const useAuth = () => React.useContext(Context);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [showLogin, setShowLogin] = React.useState<boolean | undefined>();
    const [isLoggedIn, setIsLoggedIn] = React.useState<Token | undefined>();
    const [user, setUser] = React.useState<User | null>(null);
    const [authReady, setAuthReady] = React.useState<boolean | null>(null);

    useEffect(() => {
        const restoreSession = async () => {
            const currentUser = auth.currentUser();
    
            if (currentUser) {
                try {
                    await currentUser.jwt(true);
                    setUser(auth.currentUser());
                    setIsLoggedIn(auth.currentUser()?.token);
                    setAuthReady(true);
                } catch (error) {
                    setUser(null);
                    setIsLoggedIn(undefined);
                }
            }
        };
    
        restoreSession();
    }, []);

    const onUpdateShowLogin = React.useCallback(
        (state?: boolean) => {
            setShowLogin(state);
        },
        [setShowLogin]
    );

    const onLogin = React.useCallback(
        (token?: Token) => {
            const currentUser = auth.currentUser();
            setIsLoggedIn(token);
            setUser(currentUser);
            setAuthReady(true);
        },
        [setIsLoggedIn, setUser]
    );

    const onLogout = React.useCallback(
        () => {
            auth.currentUser()?.logout();
            setIsLoggedIn(undefined);
            setUser(null);
            setAuthReady(false);
        },
        [setIsLoggedIn, setUser]
    );

    return (
        <Context.Provider
            value={{
                ...INITIAL_STATE,
                user,
                isLoggedIn,
                showLogin,
                onUpdateShowLogin,
                onLogin,
                onLogout,
                authReady,
            }}
        >
            {children}
        </Context.Provider>
    );
};