import "./src/styles.css";
import React from 'react';
import { AuthProvider } from './src/stores/AuthProvider';

export const wrapRootElement = ({ element }) => {
    return <AuthProvider>{element}</AuthProvider>;
};

export const onRouteUpdate = ({ location }) => {
    // Map old URLs to new ones
    const oldToNewUrlMap = {
        '/machnews.html': '/news',
        '/machfriends.html': '/friends',
        '/machbiog.html': '/biography',
        '/machlinx.html': '/contact',
        '/outscapes' : 'http://www.outscapes.co.uk'
    };

    const newUrl = oldToNewUrlMap[location.pathname];

    if (newUrl) {
        window.location.replace(newUrl);
    }
};